export const insurancePlansByState = {
  Arizona: [
    "Aetna Behavioral Health",
    "Blue Cross Blue Shield",
    "Cigna",
    "First Health/Coventry",
    "Humana",
    "United/Optum Behavioral Health",
    "Self-pay",
  ],
  California: [
    "Aetna/Aetna Behavioral Health",
    "Anthem Blue Cross",
    "Blue Shield of California/Magellan Healthcare",
    "Cigna/Evernorth Behavioral Healthcare",
    "ComPsych",
    "Easterseals",
    "HealthNet",
    "Kaiser",
    "Managed Health Network",
    "Provider Network of America",
    "UnitedHealthcare/Optum Behavioral Health",
    "San Francisco Health Plan",
    "Self-pay",
  ],
  Connecticut: [
    "Aetna/Aetna Behavioral Health (Includes Medical and Counseling services)",
    "Anthem Blue Cross Blue Shield",
    "Cigna/Evernorth Behavioral Healthcare",
    "ConnectiCare",
    "Humana",
    "UnitedHealthcare/Optum Behavioral Health",
    "Self-pay",
  ],
  Illinois: [
    "Aetna/Aetna Behavioral Health",
    "Blue Cross Blue Shield",
    "Cigna/Evernorth Behavioral Healthcare",
    "Provider Network of America",
    "UnitedHealthcare/Optum Behavioral Health",
    "Self-pay",
  ],
  Massachusetts: [
    "Aetna/Aetna Behavioral Health",
    "BeHealthy (Health New England)",
    "Blue Cross Blue Shield",
    "BMC Health Plan/Wellsense",
    "Cigna/Evernorth Behavioral Health",
    "Fallon Community Health Plan",
    "Harvard Pilgrim Health Plan",
    "Mass General Brigham Health Plan",
    "MassHealth (Medicaid)",
    "Tufts Health Plan",
    "Tufts Health Public Plans",
    "Tufts Health Together (MassHealth ACO)",
    "United Healthcare/Optum Behavioral Health",
    "Wellpoint (formerly UniCare)",
    "Self-pay",
  ],
  "New Jersey": [
    "Aetna/Aetna Behavioral Health",
    "Amerigroup New Jersey",
    "Cigna/Evernorth Behavioral Healthcare",
    "Horizon Blue Cross Blue Shield",
    "Optum Behavioral Health",
    "Provider Network of America",
    "Self-pay",
  ],
  "North Carolina": [
    "Aetna/Aetna Behavioral Health",
    "Blue Cross Blue Shield North Carolina",
    "Optum Behavioral Health (For ABA services only)",
    "Self-pay",
  ],
  Texas: [
    "Aetna/Aetna Behavioral Health",
    "Beech Street",
    "Blue Cross Blue Shield",
    "Cigna/Evernorth Behavioral Health",
    "First Health/Coventry",
    "Galaxy Health Network",
    "Healthcare Highways",
    "Humana POS/PPO",
    "Imagine Health",
    "IMS",
    "MultiPlan",
    "NPPN/Coalition America",
    "PHCS (Private Health Care Systems)",
    "Provider Network of America",
    "Superior/Ambetter",
    "Scott & White Health Plan",
    "Three Rivers Provider Network PPO",
    "UnitedHealthcare/Optum Behavioral Health",
    "USA Managed Care Organization",
    "Self-pay",
  ],
}
