import React from "react"
import PropTypes from "prop-types"
import Button from "components/elements/styledButton"
import "./insuranceBanner.scss"

/* InsuranceBanner */
/**
 * This banner shows a partial list of insurance companies. Items pop off the
 * end as the viewport shrinks. On mobile, the list is excluded and the button
 * text changes.
 */
const InsuranceBanner = ({
  action,
  bannerText,
  className,
  displayMode,
  providers,
  providersLogos,
}) => {
  return (
    <section className={`insurance-banner ${className}`}>
      <div className="insurance-banner__container max-container">
        <div className="insurance-banner__wrapper">
          {bannerText && (
            <span className="insurance-banner__text">{bannerText}</span>
          )}
          <ul className="insurance-banner__list">
            {displayMode === "logos" &&
              providersLogos?.map((logo, index) => (
                <img
                  alt={`Provider ${index + 1}`}
                  className="insurance-banner__item"
                  key={index}
                  src={logo.file.url}
                />
              ))}
            {displayMode === "names" &&
              providers?.map((providerName, index) => (
                <li className="insurance-banner__item" key={index}>
                  {providerName}
                </li>
              ))}
          </ul>
          {action && (
            <div className="insurance-banner__button">
              <Button
                {...action}
                aria-label={bannerText}
                color={action.color || ["ivory"]}
                isOutlined
                isSmall
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
InsuranceBanner.propTypes = {
  action: PropTypes.shape(Button.propTypes),
  bannerText: PropTypes.string,
  className: PropTypes.string,
  displayMode: PropTypes.oneOf(["logos", "names"]),
  providers: PropTypes.arrayOf(PropTypes.string),
  providersLogos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    })
  ),
}

InsuranceBanner.propTypes = InsuranceBanner.props

export default InsuranceBanner
