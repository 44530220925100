import React, { useState } from "react"
import Select from "react-select"
import "./paymentOptionsDetails.scss"
import { insurancePlansByState } from "./insurancePlansByState"

const PaymentOptionsDetails = () => {
  const [selectedState, setSelectedState] = useState("")
  const [providersList, setProvidersList] = useState([])

  const handleStateSelection = state => {
    setSelectedState(state)
    setProvidersList(insurancePlansByState[state])
  }
  const options = Object.keys(insurancePlansByState).map(state => ({
    value: state,
    label: state,
  }))

  const columns = providersList.reduce((columns, key, index) => {
    if (index % 10 === 0) {
      columns.push([key])
    } else {
      columns[columns.length - 1].push(key)
    }
    return columns
  }, [])

  return (
    <section className="container" id="sectionPaymentOptions">
      <div className="states-container">
        <div className="dropdown-container">
          <Select
            className="state-dropdown"
            onChange={selectedOption =>
              handleStateSelection(selectedOption.value)
            }
            options={options}
            placeholder="Select State"
            value={
              selectedState
                ? { value: selectedState, label: selectedState }
                : null
            }
          />
        </div>
        {Object.keys(insurancePlansByState).map(state => (
          <button
            className={
              selectedState === state
                ? "active btn-yellow"
                : "styled-btn--yellow"
            }
            key={state}
            onClick={() => handleStateSelection(state)}
          >
            {state}
          </button>
        ))}
      </div>
      {selectedState && (
        <>
          <div className="selected-provider">
            <h3>{selectedState} Insurance Coverage</h3>
          </div>
          <div className="providers-list">
            {columns.map((row, index) => (
              <ul className="providers-list-items" key={index}>
                {row.map((provider, idx) => (
                  <li key={idx}>{provider}</li>
                ))}
              </ul>
            ))}
          </div>
          {selectedState === "Massachusetts" && (
            <span className="message">
              <i>** Not all health plans are accepted at all locations **</i>
            </span>
          )}
          <div className="cta-container">
            <button className="styled-btn--yellow">
              <a
                href="https://www.corticacare.com/welcomevisit"
                rel="noopener noreferrer"
                target="_blank"
              >
                Make An Appointment
              </a>
            </button>
          </div>
        </>
      )}
    </section>
  )
}
export default PaymentOptionsDetails
