import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "components/sections/seo"
import Header from "components/sections/header"
import Footer from "components/sections/footer"
import Template from "./template"
import LOGO from "images/cortica-logo.svg"

const siteUrl = process.env.GATSBY_SITE_URL || "https://corticacare.com"

const TemplateContainer = ({ data: { footer, header, template } }) => {
  // Organization
  const schema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    logo: LOGO,
    name: "Cortica Care",
    sameAs: [
      "https://twitter.com/CorticaCare",
      "https://www.facebook.com/CorticaCare",
      "https://www.linkedin.com/company/cortica-/",
      "https://www.instagram.com/corticacare/",
    ],
    url: siteUrl,
  }

  return (
    <>
      <Seo {...template.meta} schemaMarkup={schema} slug={template.slug} />
      <Header {...header} />
      <Template {...template} />
      <Footer {...footer} />
    </>
  )
}

TemplateContainer.props = {
  ...Template.props,
  meta: PropTypes.shape(Seo.props),
}

TemplateContainer.propTypes = {
  data: PropTypes.shape({
    footer: PropTypes.shape(Footer.props),
    header: PropTypes.shape(Header.props),
    template: PropTypes.shape(TemplateContainer.props),
  }),
}

export default TemplateContainer

export const query = graphql`
  query PaymentOptionsTemplate($slug: String) {
    header: contentfulGlobalHeader(name: { eq: "Header" }) {
      announcement {
        json
      }
      menu {
        menus {
          label
          links {
            text
            path
            nestedLinks {
              text
              path
            }
          }
        }
      }
      utilityLinks {
        path
        text
      }
      cta {
        href: path
        text
      }
    }
    footer: contentfulGlobalFooter(name: { eq: "Footer" }) {
      menu {
        menus {
          label
          links {
            path
            text
          }
        }
      }
      scheduleAppointment {
        path
        text
      }
      socialLinks {
        path
        text
      }
      utilityLinks {
        path
        text
      }
    }
    template: contentfulPaymentOptionsTemplate(slug: { eq: $slug }) {
      slug
      title
      gallery {
        title
        file {
          url
        }
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      sections {
        __typename
        ... on ContentfulSectionRichText {
          __typename
          alignment
          details {
            json
          }
        }
        ... on ContentfulSectionInsuranceBanner {
          bannerText
          providers
          providersLogos {
            title
            file {
              url
            }
          }
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
        }

        ... on ContentfulSectionSplitContent {
          __typename
          backgroundColor
          reverse
          image {
            image {
              alt: description
              title
              file {
                url
              }
            }
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionFaq {
          __typename
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
          questions {
            title: question
            text: answer {
              json
            }
          }
        }
      }
    }
  }
`
