import React from "react"
import PropTypes from "prop-types"
import SECTIONS from "utils/sectionConstants"
import RichText, { RichTextProps } from "components/elements/richText"
import SplitSection from "components/sections/splitSection"
import Faq from "components/sections/faq"
import COMPONENTS from "utils/componentConstants"
import PaymentOptionsDetails from "views/paymentOptionsDetails"
import InsuranceBanner from "components/sections/insuranceBanner"

const PaymentOptionsTemplate = ({ sections }) => {
  return sections.map((section, i) => {
    switch (section.__typename) {
      case COMPONENTS.SplitContent:
        return (
          <SplitSection
            {...section}
            className="payment-options-intro"
            key={i}
          />
        )

      case COMPONENTS.InsuranceBanner:
        return (
          <InsuranceBanner
            className="payment-options-banner"
            displayMode="logos"
            key={i}
            {...section}
          />
        )
      case SECTIONS.TemplateDetailsSection:
        return <PaymentOptionsDetails {...section} key={i} />

      case SECTIONS.ContentfulSectionRichText:
        return <RichText className="payment-options" {...section} key={i} />

      case COMPONENTS.Faq:
        return <Faq {...section} key={i} />

      default:
        return null
    }
  })
}

PaymentOptionsTemplate.props = {
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(SplitSection.props),
      PropTypes.shape(InsuranceBanner.props),
      PropTypes.shape(RichTextProps),
      PropTypes.shape(Faq.props),
    ])
  ),
  slug: PropTypes.string,
  title: PropTypes.string,
}

PaymentOptionsTemplate.propTypes = PaymentOptionsTemplate.props

export default PaymentOptionsTemplate
